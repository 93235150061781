export const BASE_URL = 'http://68.183.53.74:8000';

export const QUESTION_PATH = '/api/answer/';

export const ReqMethod = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

export const Buttons = {
  enter: 'Enter',
};
