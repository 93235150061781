import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button';
import Input from '../../components/input';
import { fetchApi } from '../../helpers/api';
import { Buttons, QUESTION_PATH, ReqMethod } from '../../constants';
import { composeString } from '../../helpers/text';

import styles from './home.module.css';

function Home() {
  const { t } = useTranslation();
  const [result, setResult] = useState(null);
  const [questionId, setQuestionId] = useState(0);
  const [answer, setAnswer] = useState('');
  const [error, setError] = useState('');

  const onClick = async () => {
    try {
      const result = await fetchApi(
        QUESTION_PATH + questionId,
        ReqMethod.post,
        { text: answer.trim() },
      );
      setResult(result);
      setQuestionId(result.next_id);
      setAnswer('');
      setError('');
    } catch (e) {
      setError(t('errors.wrongAnswer'));
    }
  };

  const onChange = (text) => {
    setAnswer(text);
    setError('');
  };

  const onKeyDown = (event) => {
    if (event.key === Buttons.enter) onClick();
  };

  return (
    <div className={styles.home}>
      {result && <div>{composeString(result.next_text)}</div>}
      {result?.answer_image && (
        <img
          className={styles.resultImage}
          alt="result"
          src={result.answer_image}
        />
      )}
      {questionId !== 0 && (
        <Input onChange={onChange} onKeyDown={onKeyDown} value={answer} />
      )}
      {error && <div className={styles.error}>{error}</div>}
      <Button onClick={onClick}>
        {questionId ? t('quest.sendAnswer') : t('quest.start')}
      </Button>
    </div>
  );
}

export default Home;
