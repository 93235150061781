import reactStringReplace from 'react-string-replace';
import { URL_REGEX } from './regex';

const replacements = [
  {
    search: URL_REGEX,
    Component: 'a',
    attributes: (match) => ({
      href: match,
      target: '_blank',
      className: 'App-link',
    }),
  },
];

const stringProcessingCB = (curResult, { search, Component, attributes }) =>
  reactStringReplace(curResult, search, (match, index) => (
    <Component key={index} {...attributes(match)}>
      {match}
    </Component>
  ));

export const composeString = (source) =>
  replacements.reduce(stringProcessingCB, source);
