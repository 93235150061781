import React from 'react';
import styles from './Input.module.css';

function Input({ onChange, onKeyDown, value }) {
  return (
    <input
      className={styles.input}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyDown}
    />
  );
}

export default Input;
