import { Navigate } from 'react-router-dom';

import * as ROUTES from './constants';
import Home from '../pages/home';
import LogoView from '../pages/logo';

export const routes = [
  {
    path: ROUTES.ROOT_ALL,
    element: <Navigate to={ROUTES.ROOT} />,
  },
  {
    path: ROUTES.ROOT,
    element: <Home />,
  },
  {
    path: ROUTES.LOGO,
    element: <LogoView />,
  },
];
