import { BASE_URL } from '../constants';

export const fetchApi = async (path, method, body) => {
  const response = await fetch(BASE_URL + path, {
    method,
    headers: {
      Accept: '*/*',
      Origin: '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response.json();
};
