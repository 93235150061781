import React from 'react';

import styles from './logo.module.css';

function LogoView() {
  return (
    <img
      className={styles.appLogo}
      alt="logo"
      src={`${process.env.PUBLIC_URL}/rd.png`}
    />
  );
}

export default LogoView;
