import React from 'react';

import Layout from '../components/layout';
import Router from '../router';

import '../constants/styles.css';
import '../i18n';

function App() {
  return (
    <Layout>
      <Router />
    </Layout>
  );
}

export default App;
