import { useMemo } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { routes } from './routes';
import { mapRouteElement } from './helpers';

function AppRoutes() {
  const appRoutes = useMemo(() => routes.map(mapRouteElement), []);

  return (
    <BrowserRouter>
      <Routes>{appRoutes}</Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
